import { createMuiTheme } from '@material-ui/core/styles'

export default createMuiTheme({
  palette: {
    primary: {
      main: '#333',
    },
  },
  typography: {
    useNextVariants: true,
    fontFamily: [
      'Roboto Condensed',
      'Helvetica Neue',
      'Helvetica',
      'Arial',
      'sans-serif',
    ].join(','),
  },
  breakpoints: {
    values: {
      sm: 426,
      md: 769,
    },
  },
})
