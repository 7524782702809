const theme = {}

export const colors = {
  primary: '#199dee',
  accent: '#1382c6',
  dark: '#00407F',
  havelockBlue: '#ABE0F5',
  marble: '#f6fafc',
}


export default theme
