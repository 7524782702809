import React from 'react'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import { colors } from 'theme'

export default withStyles({
  root: {
    textTransform: 'none',
  },
  body2: {
    fontSize: 20,
  },
  colorPrimary: {
    color: '#000',
  },
  colorSecondary: {
    color: '#fff',
  },
  colorTextPrimary: {
    color: colors.dark,
  },
  colorTextSecondary: {
    color: '#7e7e7e',
  },
})(({ classes, ...props }) => (
  <Typography
    classes={classes}
    {...props}
  />
))
