import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

import Typography from 'components/Typography'

import facebookIcon from 'assets/facebook.png'
import youtubeIcon from 'assets/youtube.png'
import twitterIcon from 'assets/twitter.png'
import shamanFilmsIncIcon from 'assets/ShamanFilmsInc.png'
import documentaryChannelLogo from 'assets/documentaryChannel.png'


const useStyles = makeStyles({
  root: {
    backgroundColor: 'black',
    padding: '48px 0 24px',
    textAlign: 'center',
  },
  socialMedia: {
    display: 'flex',
    justifyContent: 'center',
  },
  socialMediaIcon: {
    marginRight: 10,
    '&:last-of-type': {
      marginRight: 0,
    },
  },
  shamanFilmsIncIcon: {
    margin: '24px auto',
    display: 'block',
  },
  copyrightText: {
    margin: '24px 0',
    textAlign: 'center',
  },
  designedByText: {
    fontSize: 14,
    marginTop: 20,
    textAlign: 'center',
    color: 'rgba(256, 256, 256, 0.5)',
  },
  documentaryChannelLogo: {
    maxWidth: 160,
    filter: 'grayscale(1)',
  },
  stacccLink: {
    color: 'rgba(256, 256, 256, 0.5)',
  },
})

const AppFooter = (props) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <div className={classes.socialMedia}>
        <a className={classes.socialMediaIcon} target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/thesearchdocumentary">
          <img src={facebookIcon} alt="facebookIcon" width="54px" height="54px" />
        </a>
        <a className={classes.socialMediaIcon} target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/channel/UCILB3ODR8Z78toylRX1iJsg/">
          <img src={youtubeIcon} alt="youtubeIcon" width="54px" height="54px" />
        </a>
        <a className={classes.socialMediaIcon} target="_blank" rel="noopener noreferrer" href="https://twitter.com/TheSearch2019">
          <img src={twitterIcon} alt="twitterIcon" width="54px" height="54px" />
        </a>
      </div>
      <img className={classes.shamanFilmsIncIcon} src={shamanFilmsIncIcon} alt="shamanFilmsIncIcon" height="100px" />
      <Typography className={classes.copyrightText} color="secondary">Shaman Films &copy; 2020</Typography>
      <img className={classes.documentaryChannelLogo} src={documentaryChannelLogo} alt="Documentary Channel" />
      <Typography className={classes.designedByText}>Designed and Powered by <a className={classes.stacccLink} rel="noopener noreferrer" href="http://staccc.com/" target="_blank">Staccc</a></Typography>
    </div>
  )
}

AppFooter.propTypes = {}
AppFooter.defaultProps = {}

export default AppFooter
