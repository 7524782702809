import React, { useState } from 'react'
import { Link, useLocation } from 'react-router-dom'

import PropTypes from 'prop-types'
import clsx from 'clsx'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'
import MenuIcon from '@material-ui/icons/Menu'
import CloseIcon from '@material-ui/icons/Close'
import IconButton from '@material-ui/core/IconButton'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Tooltip from '@material-ui/core/Tooltip'
import AppleTVBadge from 'assets/AppleTVBadge.svg'
import PrimeVideoBadge from 'assets/PrimeVideoBadge.svg'
import VimeoRentBadge from 'assets/VimeoBadge.png'

import Typography from 'components/Typography'

const useStyles = makeStyles({
  appBar: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    left: 0,
    maxWidth: 74,
  },
  toolbar: {
    minHeight: 100,
  },
  drawer: {
    // below app bar
    position: 'relative',
    zIndex: 1000,
  },
  drawerPaper: {
    backgroundColor: 'transparent',
    borderRight: 'none',
    minWidth: 264,
    backdropFilter: 'blur(30px)',
    overscrollBehavior: 'contain',
  },
  drawerHeader: {
    minHeight: 100,
    display: 'flex',
    alignItems: 'center',
    padding: '0 24px',
  },
  iconButton: {
    color: '#fff',
    border: '1px solid #fff',
    borderRadius: 0,
    marginLeft: 0,
    backgroundColor: 'transparent',
    backdropFilter: 'blur(30px)',
    position: 'fixed',
    top: 24,
    left: 24,
  },
  itemText: {
    fontSize: '1.2rem',
    color: '#fff',
  },
  item: {
    paddingTop: '1rem',
    paddingBottom: '1rem',
    paddingLeft: '2rem',
    paddingRight: '2rem',
    transition: 'background-color 100ms',
    curosr: 'pointer',
    '&:hover': {
      backgroundColor: '#ffffff',
      '& span': {
        opacity: 0.6,
        color: '#000',
      },
    },
  },
  activeItem: {
    backgroundColor: '#ffffff',
  },
  activeItemText: {
    transition: 'color 100ms',
    fontWeight: 700,
    color: '#000',
    opacity: 0.6,
  },
  externalLink: {
    display: 'flex',
    alignItems: 'center',
  },
})

const AppHeader = (props) => {
  const [open, setOpen] = useState(false)
  const classes = useStyles()
  const location = useLocation()

  const menuItems = [
    { label: 'Home', to: '/' },
    {
      label: 'AppleTV',
      tooltip: true,
      disabled: true,
      href: 'https://tv.apple.com/movie/the-search---manufacturing-belief/umc.cmc.7fspr2pbtj5xpizti7igrmnb0',
      component: <img width="150px" src={AppleTVBadge} alt="Get it on Apple TV" />,
    },
    {
      label: 'PrimeVideo',
      tooltip: true,
      disabled: true,
      href: 'https://www.amazon.com/Search-Manufacturing-Belief-Sam-Harris/dp/B08B1VFHNB/ref=sr_1_1?dchild=1&keywords=the+search+%3A+manufacturing+belief&qid=1592316991&s=instant-video&sr=1-1',
      component: <img width="150px" src={PrimeVideoBadge} alt="Get it on Prime Video" />,
    },
    {
      label: 'RentVideo',
      tooltip: true,
      disabled: true,
      href: 'http://vod.thesearch.online/',
      component: <img width="150px" src={VimeoRentBadge} alt="Get it on Vimeo on Demand" />,
    },
    { label: 'Request a Screening', to: '/group-sales' },
    { label: 'Story & Features', to: '/story-and-features' },
    { label: 'Watch Trailer', to: '/trailer' },
    { label: 'More Videos', to: '/social-assets/all' },
    { label: 'Press Kits', to: '/press-kits' },
    { label: 'Follow Us', to: '/follow-us' },
  ]

  const ToolTipContent = () => (
    <>
      <Typography><b>Available now on iTunes and Amazon</b></Typography>
      <Typography>Pre-order June 9th, 2020. Rental June 16th, 2020</Typography>
    </>
  )

  const LightTooltip = withStyles(theme => ({
    tooltip: {
      backgroundColor: theme.palette.common.white,
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: theme.shadows[1],
    },
  }))(Tooltip)


  return (
    <div>
      <IconButton
        disableRipple
        color="inherit"
        aria-label="open drawer"
        onClick={() => { setOpen(true) }}
        edge="start"
        className={classes.iconButton}
      >
        {/* @TODO animation */}
        <MenuIcon />
      </IconButton>
      <Drawer
        anchor="left"
        open={open}
        onClose={() => setOpen(false)}
        classes={{
          root: classes.drawer,
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton
            disableRipple
            aria-label="open drawer"
            onClick={() => { setOpen(false) }}
            edge="start"
            className={classes.iconButton}
          >
            {/* @TODO animation */}
            <CloseIcon />
          </IconButton>
        </div>
        <List classes={{ root: classes.list }}>
          {
            menuItems.map((x) => {
              const isActive = x.to === location.pathname

              const MenuItemContent = (
                <ListItem
                  className={clsx(
                    classes.item,
                    { [classes.activeItem]: isActive },
                  )}
                >
                  {
                    x.component
                      ? (
                        x.component
                      )
                      : (
                        <ListItemText
                          classes={{
                            primary: clsx(
                              classes.itemText,
                              { [classes.activeItemText]: isActive },
                            ),
                          }}
                          primary={x.label}
                        />
                      )
                  }
                </ListItem>
              )

              let MenuItem = x.tooltip
                ? (
                  <LightTooltip key={x.label} title={<ToolTipContent />} placement="right">
                    {MenuItemContent}
                  </LightTooltip>
                )
                : MenuItemContent

              if (x.href) {
                MenuItem = (
                  <a key={x.label} className={classes.externalLink} href={x.href} target="_blank" rel="noopener noreferrer">
                    {MenuItem}
                  </a>
                )
              }

              return (
                x.disabled
                  ? MenuItem
                  : (
                    <Link key={x.label} to={x.to}>
                      {MenuItem}
                    </Link>
                  )
              )
            })
          }
        </List>
      </Drawer>
    </div>
  )
}

AppHeader.propTypes = {}
AppHeader.defaultProps = {}

export default AppHeader
