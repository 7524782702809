import React from 'react'
import {
  BrowserRouter as Router, Route, Switch, Redirect,
} from 'react-router-dom'
import { connect } from 'react-redux'
import Loadable from 'react-loadable'
import { css } from 'emotion'

import PageTracker from 'components/PageTracker'
import AppHeader from 'components/AppHeader'
import Callback from './Auth/Callback'
import AppFooter from './components/AppFooter/AppFooter'
import AppBackground from './components/AppBackground/AppBackground'

const Landing = Loadable({
  loader: () => import(/* webpackChunkName: "landing" */ './Landing'),
  loading: () => <div>Loading Landing</div>,
})

const Trailer = Loadable({
  loader: () => import(/* webpackChunkName: "trailer" */ './Trailer'),
  loading: () => <div>Loading Trailer</div>,
})

const FollowUs = Loadable({
  loader: () => import(/* webpackChunkName: "followUs" */ './FollowUs'),
  loading: () => <div>Loading Follow Us</div>,
})

const Story = Loadable({
  loader: () => import(/* webpackChunkName: "story" */ './Story'),
  loading: () => <div>Loading Story</div>,
})

const Assets = Loadable({
  loader: () => import(/* webpackChunkName: "assets" */ './Assets'),
  loading: () => <div>Loading Assets</div>,
})

const GroupSales = Loadable({
  loader: () => import(/* webpackChunkName: "groupSales" */ './GroupSales'),
  loading: () => <div>Loading Group Sales</div>,
})

const classes = {
  root: css({
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: process.env.NODE_ENV === 'production' ? 'transparent' : 'grey',
  }),
}

const Routes = ({ loggedIn, checked }) => {
  return (
    <Router>
      <PageTracker />
      <AppHeader />
      <div className={classes.root}>
        <Switch>
          <Route path="/callback" component={Callback} />
          <Route path="/social-assets/:filter" component={Assets} />
          <Route path="/trailer" component={Trailer} />
          <Route path="/follow-us" component={FollowUs} />
          <Route path="/story-and-features" component={Story} />
          <Route path="/group-sales" component={GroupSales} />
          <Route exact path="/" component={Landing} />
          <Redirect to="/" />
        </Switch>
      </div>
      {process.env.NODE_ENV === 'production' && <AppBackground />}
      <AppFooter />
    </Router>
  )
}

const mapStateToProps = state => ({
  checked: state.app.checked,
  loggedIn: state.app.loggedIn,
})

export default connect(mapStateToProps)(Routes)
