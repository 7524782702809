import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import isMobile from 'is-mobile'

const useStyles = makeStyles({
  video: {
    minHeight: '100%',
    minWidth: '100%',
    position: 'fixed',
    bottom: 0,
    right: 0,
    filter: 'brightness(50%)',
    zIndex: -1,
  },
  mobile: {
    transform: 'translateX(calc((100% - 100vw) / 2))',
  },
  iframe: {
    width: '100%',
    height: '100%',
    position: 'relative',
    // have to force full screen based on video's aspect ratio
    '@media (max-aspect-ratio: 16/9)': {
      width: '177.78vh',
      left: 'calc((177.78vh - 100vw)/2)', // when video is less than 16/9, it must be manually aligned
    },
    '@media (min-aspect-ratio: 16/9)': {
      height: '56.25vw',
    },
  },
})

const AppBackground = (props) => {
  const classes = useStyles()

  return (
    <video className={clsx(classes.video, classes.mobile)} loop autoPlay muted>
      <source src="https://s3.ca-central-1.amazonaws.com/www.staccc.com/720p+the+search+v3.mp4" type="video/mp4" />
        Your browser does not support viewing this content, please access to the page using Google Chrome.
    </video>
  )

  // if (isMobile()) {
  //   return (
  //     <video className={clsx(classes.video, classes.mobile)} loop autoPlay muted>
  //       <source src="https://s3.ca-central-1.amazonaws.com/www.staccc.com/720p+the+search+v3.mp4" type="video/mp4" />
  //       Your browser does not support viewing this content, please access to the page using Google Chrome.
  //     </video>
  //   )
  // }

  // return (
  //   <div className={classes.video}>
  //     <iframe
  //       className={classes.iframe}
  //       src="https://www.youtube.com/embed/TRYCJdPu3W0?controls=0&autoplay=1&loop=1&playlist=TRYCJdPu3W0"
  //       frameBorder="0"
  //       allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
  //       allowFullScreen
  //     />
  //   </div>
  // )
}

AppBackground.propTypes = {}
AppBackground.defaultProps = {}

export default AppBackground
